define("discourse/plugins/chat/discourse/routes/admin-plugins-show-discourse-chat-incoming-webhooks-new", ["exports", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/routes/discourse", "discourse/plugins/chat/discourse/models/chat-channel"], function (_exports, _object, _service, _ajax, _ajaxError, _discourse, _chatChannel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DiscourseChatIncomingWebhooksNew extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "adminPluginNavManager", [_service.service]))();
    #adminPluginNavManager = (() => (dt7948.i(this, "adminPluginNavManager"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    async model() {
      if (!this.currentUser?.admin) {
        return {
          model: null
        };
      }
      try {
        const model = await (0, _ajax.ajax)("/admin/plugins/chat/hooks/new.json");
        model.webhook = _object.default.create(model.webhook);
        model.webhook.chat_channel = _chatChannel.default.create(model.webhook.chat_channel);
        model.chat_channels = model.chat_channels.map(channel => _chatChannel.default.create(channel));
        return model;
      } catch (err) {
        (0, _ajaxError.popupAjaxError)(err);
      }
    }
  }
  _exports.default = DiscourseChatIncomingWebhooksNew;
});