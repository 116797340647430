define("discourse/plugins/chat/discourse/templates/admin-plugins/show/discourse-chat-incoming-webhooks/new", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="admin-detail discourse-chat-incoming-webhooks">
    <BackButton
      @label="chat.incoming_webhooks.back"
      @route="adminPlugins.show.discourse-chat-incoming-webhooks.index"
      class="incoming-chat-webhooks-back"
    />
  
    <ChatIncomingWebhookEditForm @chatChannels={{model.chat_channels}} />
  </div>
  */
  {
    "id": "R408NtAQ",
    "block": "[[[10,0],[14,0,\"admin-detail discourse-chat-incoming-webhooks\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"incoming-chat-webhooks-back\"]],[[\"@label\",\"@route\"],[\"chat.incoming_webhooks.back\",\"adminPlugins.show.discourse-chat-incoming-webhooks.index\"]],null],[1,\"\\n\\n  \"],[8,[39,1],null,[[\"@chatChannels\"],[[30,0,[\"model\",\"chat_channels\"]]]],null],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/chat/discourse/templates/admin-plugins/show/discourse-chat-incoming-webhooks/new.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"back-button\",\"chat-incoming-webhook-edit-form\"]]",
    "moduleName": "discourse/plugins/chat/discourse/templates/admin-plugins/show/discourse-chat-incoming-webhooks/new.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});