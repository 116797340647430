define("discourse/plugins/chat/discourse/templates/admin-plugins/show/discourse-chat-incoming-webhooks/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <DBreadcrumbsItem
    @path="/admin/plugins/chat/hooks"
    @label={{i18n "chat.incoming_webhooks.title"}}
  />
  
  <div class="discourse-chat-incoming-webhooks admin-detail">
    <AdminPageSubheader
      @titleLabel="chat.incoming_webhooks.title"
      @descriptionLabel="chat.incoming_webhooks.instructions"
    >
      <:actions as |actions|>
        <actions.Primary
          @label="chat.incoming_webhooks.new"
          @title="chat.incoming_webhooks.new"
          @route="adminPlugins.show.discourse-chat-incoming-webhooks.new"
          @routeModels="chat"
          class="admin-incoming-webhooks-new"
        />
      </:actions>
    </AdminPageSubheader>
  
    <div class="incoming-chat-webhooks">
      {{#if this.model.incoming_chat_webhooks}}
        <AdminChatIncomingWebhooksList
          @webhooks={{this.model.incoming_chat_webhooks}}
        />
      {{else}}
        {{i18n "chat.incoming_webhooks.none"}}
      {{/if}}
    </div>
  </div>
  */
  {
    "id": "e+y97eSF",
    "block": "[[[8,[39,0],null,[[\"@path\",\"@label\"],[\"/admin/plugins/chat/hooks\",[28,[37,1],[\"chat.incoming_webhooks.title\"],null]]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"discourse-chat-incoming-webhooks admin-detail\"],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@titleLabel\",\"@descriptionLabel\"],[\"chat.incoming_webhooks.title\",\"chat.incoming_webhooks.instructions\"]],[[\"actions\"],[[[[1,\"\\n      \"],[8,[30,1,[\"Primary\"]],[[24,0,\"admin-incoming-webhooks-new\"]],[[\"@label\",\"@title\",\"@route\",\"@routeModels\"],[\"chat.incoming_webhooks.new\",\"chat.incoming_webhooks.new\",\"adminPlugins.show.discourse-chat-incoming-webhooks.new\",\"chat\"]],null],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\\n  \"],[10,0],[14,0,\"incoming-chat-webhooks\"],[12],[1,\"\\n\"],[41,[30,0,[\"model\",\"incoming_chat_webhooks\"]],[[[1,\"      \"],[8,[39,4],null,[[\"@webhooks\"],[[30,0,[\"model\",\"incoming_chat_webhooks\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,1],[\"chat.incoming_webhooks.none\"],null]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"actions\"],false,[\"d-breadcrumbs-item\",\"i18n\",\"admin-page-subheader\",\"if\",\"admin-chat-incoming-webhooks-list\"]]",
    "moduleName": "discourse/plugins/chat/discourse/templates/admin-plugins/show/discourse-chat-incoming-webhooks/index.hbs",
    "isStrictMode": false
  });
});